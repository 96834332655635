import { CONTENT_API_URL } from './constants';
import { QueryClient } from '@tanstack/react-query';
import { getApiBaseUrl, getCurrentHostname } from './api-domains';

interface ErrorResponse {
  message: string;
  code: number;
  reason?: string;
}

const isEmpty = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const client = async (
  endpoint: string,
  { token, formData, isV2 = false, ...inputData }: any = {},
  method = 'GET',
  headers = {},
  customBaseUrl?: string,
): Promise<any> => {
  const reqBody = { ...inputData };
  const limitToken = inputData?.limit_token;
  const cashoutToken = inputData?.cashout_token;
  if (limitToken) {
    reqBody.token = limitToken;
    delete reqBody['limit_token'];
  }
  if (cashoutToken) {
    reqBody.token = cashoutToken;
    delete reqBody['cashout_token'];
  }

  const config: any = {
    method: !isEmpty(reqBody) && method === 'GET' ? 'POST' : method,
    body:
      !isEmpty(reqBody) && !formData
        ? JSON.stringify(reqBody)
        : formData
          ? formData
          : undefined,
    headers: {
      'Content-Type': 'application/json',
      Accept: !formData
        ? isV2
          ? 'application/vnd.s.v2+json'
          : 'application/vnd.s.v1+json'
        : 'multipart/form-data',
      Cookie: token && `_casino_session=${token}`,
      ...headers,
    },
  };

  if (formData) {
    delete config.headers['Content-Type'];
  }

  const hostname = getCurrentHostname();
  const baseUrl = customBaseUrl || getApiBaseUrl(hostname);
  const url = `${baseUrl}/${endpoint}`;

  const response = await fetch(url, {
    credentials: 'include',
    ...config,
  });
  if (response.status === 401 || response.status === 403) {
    const data = await response.text();
    let reason;

    try {
      reason = JSON.parse(data).reason;
    } catch (e) {
      console.log('Error parsing response:', e);
    }

    let errorMessage =
      response.status === 401
        ? 'Unauthorized action. Please contact Customer Support.'
        : 'Access denied. Please contact Customer Support.';

    const error: ErrorResponse = {
      message: errorMessage,
      code: 401,
    };

    if (reason !== undefined) {
      error.reason = reason;
    }

    return Promise.reject(error);
  }

  try {
    const data = await response.text();
    if (response.ok) {
      return (data && JSON.parse(data)) || {};
    } else {
      return Promise.reject(JSON.parse(data));
    }
  } catch (e) {
    return Promise.reject({ message: 'Internal system error', code: 500 });
  }
};

export const CONTENT_ENDPOINT = `${CONTENT_API_URL}/graphql`;

export const prefetchQuery = async (
  queryName: string,
  reactQueryClient: QueryClient,
  queryFn?: any,
  variables?: any,
  options?: any,
): Promise<any> => {
  const data = await queryFn(variables);
  const queryClient = reactQueryClient;
  await queryClient?.prefetchQuery({
    queryKey: variables !== undefined ? [queryName, variables] : [queryName],
    queryFn: async ({ queryKey }) => queryFn(queryKey[1]),
    ...options,
  });
  return data;
};

export default client;
