'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

export const createQueryString = (name: string, value: string) => {
  const params = new URLSearchParams();
  params.set(name, value);

  return params.toString();
};

type OpenParam = {
  [key: string]: string;
};

type ModalTypes =
  | 'signin'
  | 'signup'
  | 'forgot-pass'
  | 'challenges'
  | 'regular-wheel'
  | 'jungle-wheel'
  | 'vip-wheel'
  | 'prize'
  | 'wild-points'
  | 'confirm_limit'
  | 'bonus-selection'
  | 'details'
  | 'wallet'
  | 'wallet-settings'
  | 'promo-code'
  | 'restricted-region'
  | 'refer_friend'
  | 'avatar'
  | 'fill-profile'
  | 'common-wheel'
  | 'rare-wheel'
  | 'epic-wheel'
  | 'weekly-common-wheel'
  | 'weekly-rare-wheel'
  | 'weekly-epic-wheel'
  | string;

export const useCurrentModal = () => {
  const searchParams = useSearchParams();

  const modal = searchParams.get('m');
  return modal;
};

export const openModal = (name: ModalTypes, params?: OpenParam) => {
  let queryString = `${createQueryString('m', name)}`;

  if (params) {
    Object.keys(params).forEach((key) => {
      queryString += `&${createQueryString(key, params[key])}`;
    });
  }
  window.history.pushState(null, '', `?${queryString}`);
};

const useModal = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const close = useCallback(
    (params?: string[]) => {
      const urlSearchParams = new URLSearchParams(searchParams?.toString());
      urlSearchParams.delete('m');
      if (params) {
        for (const param of params) {
          if (urlSearchParams.has(param)) {
            urlSearchParams.delete(param);
          }
        }
      }
      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';
      window.history.replaceState(null, '', `${pathname}${query}`);
    },
    [pathname, searchParams],
  );

  const closeOnly = useCallback(
    (params?: string[]) => {
      const urlSearchParams = new URLSearchParams(searchParams?.toString());
      if (params) {
        for (const param of params) {
          if (urlSearchParams.has(param)) {
            urlSearchParams.delete(param);
          }
        }
      }
      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';
      window.history.replaceState(null, '', `${pathname}${query}`);
    },
    [pathname, searchParams],
  );

  const replace = useCallback(
    (name: string, newValue: string) => {
      const urlSearchParams = new URLSearchParams(searchParams.toString());
      urlSearchParams.set(name, newValue);
      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';
      window.history.replaceState(null, '', `${pathname}${query}`);
    },
    [pathname, searchParams],
  );

  const create = useCallback(
    (name: string, value: string) => {
      const urlSearchParams = new URLSearchParams(searchParams?.toString());

      urlSearchParams.append(name, value);

      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';

      window.history.replaceState(null, '', `${pathname}${query}`);
    },
    [pathname, searchParams],
  );

  return {
    open: openModal,
    close,
    replace,
    create,
    closeOnly,
  };
};

export default useModal;
