'use client';

import React, { useContext } from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
// @ts-ignore
import createHash from 'sha.js';
import client from '../utils/api';
import { GENERAL_ERROR, INVALIDATE_GLOBAL_QUERIES } from '@/utils/constants';
import { LiveChatLoaderContext } from '@/services/live-chat/context';
import useRecaptcha from './recaptcha';
import { AuthContext } from '@/app/providers/auth';
import { usePathname, useRouter } from 'next/navigation';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';
import { useAnalytics } from '@/hooks/use-analytics';

const useLogin = () => {
  const queryClient = useQueryClient();
  const { boot, shutdown } = useContext(LiveChatLoaderContext);
  const { handleReCaptchaVerify } = useRecaptcha();
  const { track, reset } = useAnalytics();

  return useMutation({
    mutationFn: async (data: any) => {
      const { user } = data;
      const encryptedPassword =
        user.password &&
        user.password.length > 0 &&
        createHash('sha256').update(user.password, 'utf8').digest('hex');
      const captchaToken = await handleReCaptchaVerify('signin');
      const payload = {
        user: {
          email: user.email,
          password: encryptedPassword,
          otp_attempt: user.otp_attempt,
          captcha: captchaToken,
        },
      };
      return client('users/sign_in', payload, 'POST');
    },
    onSuccess: (data) => {
      reset();
      // Clear live chat before identifying new user
      shutdown();
      boot(data);
      track('signin_form_succeeded', {
        category: 'auth',
        user_id: data?.id,
      });
      INVALIDATE_GLOBAL_QUERIES.forEach((query) => {
        queryClient.invalidateQueries({ queryKey: [query] });
      });
    },
    onError: (err: any) => {
      if (err?.required?.includes('captcha')) {
        handleReCaptchaVerify('signin');
        track('signin_form_captcha_failed', {
          category: 'auth',
        });
      }
      track('signin_form_failed', {
        category: 'auth',
        label: JSON.stringify(err?.errors),
      });
    },
  });
};

const useRegister = () => {
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { identify: identifyChat } = useContext(LiveChatLoaderContext);
  const { handleReCaptchaVerify } = useRecaptcha();
  const { track } = useAnalytics();
  return useMutation({
    mutationFn: async (data: any) => {
      const { user, currency } = data;
      const isStandalone = data?.isStandalone;

      const encryptedPassword =
        user.password &&
        user.password.length > 0 &&
        createHash('sha256').update(user.password, 'utf8').digest('hex');

      const captchaToken = await handleReCaptchaVerify('signup');

      const payload = {
        user: {
          email: user.email,
          password: encryptedPassword,
          terms_acceptance: user.terms_acceptance,
          profile_attributes: {
            currency,
          },
          receive_promos: true,
          captcha: captchaToken,
        },
      };

      const standaloneHeader = isStandalone
        ? { 'X-Platform-App': 'sportsbook' }
        : {};

      return client('users', payload, 'POST', standaloneHeader);
    },
    onSuccess: (data) => {
      identifyChat(data);
      track('signup_form_succeeded', {
        category: 'auth',
        user_id: data?.id,
      });
      INVALIDATE_GLOBAL_QUERIES.forEach((query) => {
        queryClient.invalidateQueries({ queryKey: [query] });
      });
      toast({
        state: 'success',
        title: toastMessages?.register_success,
        autoClose: 1500,
      });
    },
    onError: (err: any) => {
      if (err?.errors?.captcha?.invalid === 'is invalid') {
        handleReCaptchaVerify('signup');
        track('signup_form_captcha_failed', {
          category: 'auth',
        });
      }
      track('signup_form_failed', {
        category: 'auth',
        label: JSON.stringify(err?.errors),
      });
    },
  });
};

const useResetInstructions = () => {
  const { handleReCaptchaVerify } = useRecaptcha();
  return useMutation({
    mutationFn: async (data: any) => {
      const { user } = data;
      const captchaToken = await handleReCaptchaVerify('forgotPassword');
      try {
        const payload = {
          user: {
            email: user.email,
            captcha: captchaToken,
          },
        };
        return client('users/password', payload, 'POST');
      } catch (e: any) {
        if (e?.errors?.required && e?.errors?.required.includes('captcha')) {
          handleReCaptchaVerify('forgotPassword');
        }
        throw e;
      }
    },
  });
};

const useChangePassword = () => {
  const { handleReCaptchaVerify } = useRecaptcha();
  return useMutation({
    mutationFn: async (data: any) => {
      const { password, password_confirmation, reset_password_token } = data;
      const encryptedPassword =
        password &&
        password.length > 0 &&
        createHash('sha256').update(password, 'utf8').digest('hex');
      const encryptedPasswordConfirm =
        password_confirmation &&
        password_confirmation.length > 0 &&
        createHash('sha256')
          .update(password_confirmation, 'utf8')
          .digest('hex');
      const captchaToken = await handleReCaptchaVerify('resetPassword');
      try {
        const payload = {
          user: {
            reset_password_token,
            password: encryptedPassword,
            password_confirmation: encryptedPasswordConfirm,
            captcha: captchaToken,
          },
        };
        return client('users/password', payload, 'PUT');
      } catch (e: any) {
        if (e?.errors?.required && e?.errors?.required.includes('captcha')) {
          handleReCaptchaVerify('resetPassword');
        }
        throw e;
      }
    },
  });
};

const useUpdatePassword = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      const { email, password, current_password, password_confirmation } = data;
      const encryptedCurrentPassword =
        current_password &&
        current_password.length > 0 &&
        createHash('sha256').update(current_password, 'utf8').digest('hex');
      const encryptedPassword =
        password &&
        password.length > 0 &&
        createHash('sha256').update(password, 'utf8').digest('hex');
      const encryptedPasswordConfirm =
        password_confirmation &&
        password_confirmation.length > 0 &&
        createHash('sha256')
          .update(password_confirmation, 'utf8')
          .digest('hex');
      try {
        const payload = {
          user: {
            email,
            current_password: encryptedCurrentPassword,
            password: encryptedPassword,
            password_confirmation: encryptedPasswordConfirm,
          },
        };
        return client('users', payload, 'PUT');
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });
};

const useLogout = () => {
  const queryClient = useQueryClient();
  const { shutdown, boot } = useContext(LiveChatLoaderContext);
  const router = useRouter();
  const pathname = usePathname();
  const { reset } = useAnalytics();

  return useMutation({
    mutationKey: ['logoutMutation'],
    mutationFn: () => client('users/sign_out', {}, 'DELETE'),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      INVALIDATE_GLOBAL_QUERIES.map((query) => {
        return queryClient.invalidateQueries({ queryKey: [query] });
      });
      localStorage.removeItem('awaitingDeposit');
      shutdown();
      boot();
      reset();
    },
  });
};

const useUnlockAccount = () => {
  return useMutation({
    mutationFn: async (unlock_token) =>
      await client(`users/unlock?unlock_token=${unlock_token}`, {}, 'GET'),
    retry: false,
  });
};

const useConfirmAuthProvider = () => {
  const { boot, shutdown } = useContext(LiveChatLoaderContext);
  const router = useRouter();

  const toastMessages = useOtherToastMessages();

  const { toast } = useToast();
  const { track, reset } = useAnalytics();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (confirmation_token) =>
      await client(
        `auth_providers/confirm`,
        {
          confirmation_token,
        },
        'POST',
      ),
    onSuccess: (data) => {
      reset();
      shutdown();
      boot(data);
      track('auth_provider_confirmation_succeeded', {
        category: 'auth',
        user_id: data?.id,
      });
      INVALIDATE_GLOBAL_QUERIES.map((query) => {
        return queryClient.invalidateQueries({ queryKey: [query] });
      });
    },
    onError: (err: any) => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
      const url = new URL(window.location.href);
      url.searchParams.delete('confirmation_token');
      url.searchParams.delete('isRedirected');
      window.history.replaceState({}, '', url.toString());
    },
    retry: false,
  });
};

const useConfirmation = (confirmation_token?: string) => {
  return useQuery({
    queryKey: ['confirmationToken'],
    queryFn: async () => {
      return await client(
        `users/confirmation?confirmation_token=${confirmation_token}`,
        {},
        'GET',
      );
    },
    retry: false,
    enabled: confirmation_token !== undefined,
  });
};

const useUpdateDetails = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      email: string;
      terms_acceptance: boolean;
      currency: string;
      // receive_promos?: boolean;
    }) => {
      const payload = {
        user: {
          email: data.email,
          terms_acceptance: data.terms_acceptance,
          currency: data.currency,
          // receive_promos: data.receive_promos,
        },
      };
      return client('auth_providers/update_details', payload, 'POST');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      track('new_terms_accepted', {
        category: 'auth',
      });
    },
    onError: (err: any) => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
      track('terms_update_failed', {
        category: 'auth',
        label: err?.errors,
      });
    },
  });
};

const useOAuthProvider = () => {
  return useQuery({
    queryKey: ['authProviders'],
    queryFn: () => client(`info/auth_providers`, {}, 'GET'),
  });
};

const useOAuthProvidersDetails = () => {
  return useQuery({
    queryKey: ['authProviderDetails'],
    queryFn: () => client(`auth_providers`, {}, 'GET'),
  });
};

const useRemoveOauth = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (authId: number) =>
      await client(`auth_providers/${authId}`, {}, 'DELETE'),
    onSuccess: () => {
      toast({
        state: 'success',
        title: 'OAuth provider removed',
      });
      queryClient.invalidateQueries({ queryKey: ['authProviderDetails'] });
    },
    onError: () => {
      toast({
        state: 'error',
        title: GENERAL_ERROR,
      });
    },
    retry: false,
  });
};

const useOAuthProviderRoutes = () => {
  return useQuery({
    queryKey: ['authProvidersRoutes'],
    queryFn: () => client(`info/auth_providers_routes`, {}, 'GET'),
  });
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export {
  useRemoveOauth,
  useConfirmAuthProvider,
  useOAuthProviderRoutes,
  useLogin,
  useRegister,
  useLogout,
  useConfirmation,
  useResetInstructions,
  useChangePassword,
  useUpdatePassword,
  useAuth,
  useUpdateDetails,
  useUnlockAccount,
  useOAuthProvider,
  useOAuthProvidersDetails,
};
