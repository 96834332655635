interface ConfigType {
  API: API;
  SITE_URL: string | undefined;
  API_URL_BASE: string | undefined;
  COINMARKETCAP_API: string | undefined;
  DEFAULT_CURRENCY: string | undefined;
  RECAPTCHA_KEY: string | undefined;
  AVATAR_SECRET: string | undefined;
  DEFAULT_LANG: string | undefined;
  SHOWN_WILD_CATEGORIES: Array<string>;
  SHOWN_WILD_COLLECTIONS: Array<string>;
  ALL_AVAILABLE_COLLECTIONS: Array<string>;
  PAYMENT_PROVIDERS: Array<string>;
  CRYPTO_CURRENCIES: Array<string>;
  baseUrl: string;
  CHAT_API_KEY: string | undefined;
  CURACAO_TOKEN: string | undefined;
}

interface API {
  HOST: string | undefined;
  URL: string | undefined;
}

const config: ConfigType = {
  API_URL_BASE: 'https://www.wild.io',
  API: {
    HOST: process.env.NEXT_PUBLIC_BACKEND_HOST,
    URL: process.env.NEXT_PUBLIC_BACKEND_API_URL,
  },
  SITE_URL: process.env.NEXT_PUBLIC_SITE_URL || 'https://wild.io',
  COINMARKETCAP_API: process.env.NEXT_PUBLIC_COINMARKETCAP_API,
  AVATAR_SECRET: process.env.NEXT_PUBLIC_AVATAR_API_SECRET,
  RECAPTCHA_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
  CHAT_API_KEY: process.env.NEXT_PUBLIC_APP_ID_TALKJS,
  DEFAULT_CURRENCY: 'BTC',
  DEFAULT_LANG: 'en',
  ALL_AVAILABLE_COLLECTIONS: [
    'all',
    'slots',
    'fresh-releases',
    'jackpot',
    'card',
    'top-games',
    'accumulating',
    'live-casino',
    'roulette',
    'casual',
    'fishing',
    'lottery',
    'craps',
    'poker',
    'video-poker',
    'table-games',
    'bonus-buy',
    'christmas',
    'skill-games',
    'blackjack',
    'crash-games',
    'game-shows',
    'megaways',
    'baccarat',
    'vip-games',
    'halloween-games',
    'booming-tournament',
    'table-live-games',
    'provably-fair',
    'egyptian',
    'fruit-games',
    'easter-games',
    'st-patrick-games',
    'sport-games',
    'valentine-games',
    'hold-and-win',
    'asian-games',
    'low-volatility',
    'music',
    'high-volatility',
    'bgaming-slotmania',
    'booming-bonanza',
    'game-week',
    'endorphina-tournament',
    'gamebeat-blitz',
    'wazdan-tournament',
    'oktoberfest-promo',
    'pragmatic-play-tournament',
    'mascot-tournament',
    'endorphina-challenge',
    'kagaming-tournament',
    'belatra-reel-thrill',
    'providers',
    'themes',
    // Here you can add/remove collections when
    // marketing requires them
  ],
  SHOWN_WILD_COLLECTIONS: [
    'fruit-games',
    'crash-games',
    'megaways',
    'low-volatility',
    'high-volatility',
    'hold-and-win',
    'egyptian',
    'game-shows',
    'asian-games',
    'provably-fair',
    'easter-games',
    'st-patrick-games',
    'valentine-games',
    'halloween',
    'fishing',
    'sport-games',
    'music',
    'video-poker',
  ],
  SHOWN_WILD_CATEGORIES: [
    'all',
    'blackjack',
    'bonus-buy',
    'game-shows',
    'jackpot',
    'megaways',
    'live-casino',
    'roulette',
    'slots',
    'table-games',
    'crash-games',
    'skill-games',
    'new',
    'popular',
    'bgaming-slotmania',
  ],
  PAYMENT_PROVIDERS: [
    'devcode',
    'accentpay',
    'payment_center',
    'coinspaid',
    'apco',
    'skrill',
  ],
  CRYPTO_CURRENCIES: ['BTC', 'ETH', 'BCH', 'LTC', 'DOGE', 'USDT'],
  baseUrl: 'https://www.wild.io',

  CURACAO_TOKEN:
    'ZXlKcGRpSTZJalkwUkdSWlMwTkNaR05FYUdsSldHSjFlbWxxYzJjOVBTSXNJblpoYkhWbElqb2laRnBQTWxSWU9XUmxPR1pOTVU5U1pVbHJhamhaUVQwOUlpd2liV0ZqSWpvaVl6RXhaamM1TmpjeVlqWTNNREZsTVdabVpHVmtZV0l3T0dWaFlXSmtaRFExTmpsbVlUQTFPR1JtWTJVeE5Ua3hZakExWldaak5tVTBPVE5tTlRoaE9DSXNJblJoWnlJNklpSjk',
};

export default config;
