'use client';
import React, { useState } from 'react';

export const XS_BREAKPOINT = '(max-width: 380px)';
export const SM_BREAKPOINT = '(min-width: 640px)';
export const MD_BREAKPOINT = '(min-width: 768px)';
export const LG_BREAKPOINT = '(min-width: 1024px)';
export const XL_BREAKPOINT = '(min-width: 1280px)';
export const TWOXL_BREAKPOINT = '(min-width: 1536px)';
export const MOBILE_BREAKPOINT = '(max-width: 640px)';
export const MOBILE_LG_BREAKPOINT = '(max-width: 768px)';
export const TABLET_BREAKPOINT = '(max-width: 1024px)';
export const LAPTOP_BREAKPOINT = '(max-width: 1280px)';
export const DESKTOP_BREAKPOINT = '(min-width: 1024px)';

const useMediaQuery = (mediaQueryString: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [matches, setMatches] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const listener = () => setMatches(!!mediaQueryList.matches);
    listener();
    mediaQueryList.addEventListener('change', listener);
    return () => mediaQueryList.removeEventListener('change', listener);
  }, [mediaQueryString]);

  return { matchesQuery: matches, isLoading };
};

export default useMediaQuery;
