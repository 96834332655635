'use client';

import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { LiveChatLoaderContext } from '@/services/live-chat/context';
import { useCookies } from 'react-cookie';
import client from '@/utils/api';
import { User } from '@/types/user';

interface AuthProps {
  user: {
    isAuthenticated: boolean;
    isLoading: boolean;
    isFetching: boolean;
    refetch: () => void;
    currentUser: User | null;
  };
}

export const AuthContext = React.createContext<AuthProps>({} as AuthProps);
AuthContext.displayName = 'AuthContext';

const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children, ...props }) => {
  const [cookies] = useCookies(['_casino_session']);

  const { boot } = React.useContext(LiveChatLoaderContext);
  const {
    isLoading: userLoading = true,
    data: user,
    isFetching,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ['currentUser'],
    queryFn: () =>
      client('player', { token: cookies?._casino_session }).then((res: any) => {
        boot(res);
        return res;
      }),
  });

  const currentUser = React.useMemo(() => {
    return user;
  }, [user]);

  const isAuthenticated = React.useMemo(
    () => (!userLoading && user?.email ? true : false),
    [userLoading, user],
  );

  const authValue = React.useMemo(() => {
    return {
      user: {
        isAuthenticated,
        isLoading: userLoading,
        isFetching,
        refetch: refetchUser,
        currentUser: currentUser || user || {},
      },
    };
  }, [
    currentUser,
    isAuthenticated,
    refetchUser,
    user,
    userLoading,
    isFetching,
  ]);
  return (
    <AuthContext.Provider value={authValue} {...props}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
