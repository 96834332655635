'use client';

import { createContext } from 'react';

import { Provider, State } from './types';
import { User } from '@/types/user';

interface Context {
  provider: Provider;
  providerKey: string;
  state: State;
  setState: (state: State) => void;
  appID?: string;
  locale?: string;
  idlePeriod?: number;
  baseUrl?: string;
  identify: (user?: User | undefined, options?: any) => void;
  boot: (user?: User | undefined, options?: any) => void;
  update: (data?: any) => void;
  shutdown: () => void;
  beforeInit?: () => void;
  onReady?: () => void;
}

export const LiveChatLoaderContext = createContext<Context>({} as Context);
