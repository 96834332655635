import config from '@/config';
import type { NextRequest } from 'next/server';
import { MIRROR_DOMAINS } from '@/utils/constants';
/**
 * List of domains that should bypass mirror domain logic
 */
export const BYPASS_DOMAINS = ['staging.wild.io'];

/**
 * Gets the current domain in server-side context (middleware)
 */
export const getCurrentDomainServer = (req: NextRequest): string => {
  return (
    req.headers.get('host') ||
    new URL(config.SITE_URL || 'https://wild.io').host
  );
};

/**
 * Checks if a hostname matches any of the mirror domains
 */
export const isMirrorDomain = (hostname: string): boolean => {
  return MIRROR_DOMAINS.some((domain) => {
    const currentDomain = hostname.split(':')[0];
    const configDomain = domain.split(':')[0];
    return currentDomain === configDomain;
  });
};

/**
 * Gets the main domain URL object
 */
export const getMainDomain = () => {
  try {
    return new URL(config.SITE_URL || '');
  } catch (e) {
    return new URL(
      process.env.NODE_ENV === 'development'
        ? 'http://wild.test:3000'
        : 'https://wild.io',
    );
  }
};
