import { useAuth } from './auth';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

interface AnalyticsType {
  track: (name: string, data: any) => void;
  identify: (id: string, data: any) => void;
  reset: () => void;
  page: () => void;
}

declare global {
  interface Window {
    analytics: AnalyticsType;
  }
}

export const useAnalytics = () => {
  const { user } = useAuth();

  const track = (eventName: string, data: any = {}) => {
    if (process.env.NODE_ENV !== 'production') return;

    // Track with Google Tag Manager
    const gtmPayload: any = {
      event: 'gaTrack',
      event_action: eventName,
      event_category: data?.category || '',
      event_label: data?.label || '',
    };

    const userId = data?.user_id ?? user?.currentUser?.id;

    if (userId != null) {
      gtmPayload['user_id'] = `wildcasino:${userId}`;
    }

    window?.dataLayer && window?.dataLayer.push(gtmPayload);
  };

  const page = (url: string, userId: string) => {
    if (process.env.NODE_ENV !== 'production') return;

    // Page tracking for GTM
    window?.dataLayer &&
      window?.dataLayer.push({
        event: 'pageview',
        user_id: `wildcasino:${userId}`,
        page_path: url,
      });

    // (usePathname and useSearchParams) to ensure dynamic routing is captured
    // accurately, especially with client-side navigations.
    // TODO: Evaluate whether the component-based approach provides more reliable
    // tracking than manually triggering pageviews via the service.
  };

  const reset = () => {
    if (process.env.NODE_ENV !== 'production') return;
  };

  return { track, page, reset };
};
