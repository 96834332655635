import config from '@/config';
import { isMirrorDomain } from './domains';

/**
 * Gets the API base URL based on the current hostname
 */
export const getApiBaseUrl = (hostname: string): string => {
  const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';

  // If it's a mirror domain, use that domain for API calls
  if (isMirrorDomain(hostname)) {
    return `${protocol}://www.${hostname}/api`;
  }

  // Otherwise use the default API URL
  return process.env.NODE_ENV === 'development'
    ? 'http://wild.test:3000/v1'
    : `${config.API_URL_BASE}/api`;
};

/**
 * Gets the current hostname from window.location
 */
export const getCurrentHostname = (): string => {
  if (typeof window !== 'undefined') {
    return window.location.host;
  }
  return new URL(config.SITE_URL || 'https://wild.io').host;
};
