import { useMemo } from 'react';
import { getIconByCategory } from './helpers';
import { Metadata } from 'next';

export const CONTENT_API_URL =
  process.env.NEXT_PUBLIC_CONTENT_API_URL || 'https://cms.wild.io';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

export const APP_NAME = 'Wild.io Bitcoin Casino';
export const APP_DEFAULT_TITLE =
  'Wild.io Crypto Casino | Licensed Online Bitcoin Casino';
export const APP_TITLE_TEMPLATE =
  '%s Crypto Casino | Licensed Online Bitcoin Casino';
export const APP_DESCRIPTION =
  'Rack up wins from a wide selection of crypto casino games and enjoy lightning-fast deposits and withdrawals for BTC, BCH, ETH, LTC, XRP, TRX, ADA!';

export const METADATA_OBJECT: Metadata = {
  applicationName: APP_NAME,
  title: {
    default: APP_DEFAULT_TITLE,
    template: APP_TITLE_TEMPLATE,
  },
  description: APP_DESCRIPTION,
  appleWebApp: {
    capable: true,
    statusBarStyle: 'default',
    title: APP_DEFAULT_TITLE,
    // startUpImage: [],
  },
  formatDetection: {
    telephone: false,
  },
  openGraph: {
    type: 'website',
    siteName: APP_NAME,
    title: {
      default: APP_DEFAULT_TITLE,
      template: APP_TITLE_TEMPLATE,
    },
    description: APP_DESCRIPTION,
    images: 'https://wild.io/assets/og_image.png',
    url: 'https://wild.io',
  },
  twitter: {
    card: 'summary',
    site: '@wildcasinohq',
    title: {
      default: APP_DEFAULT_TITLE,
      template: APP_TITLE_TEMPLATE,
    },
    description: APP_DESCRIPTION,
    images: 'https://wild.io/assets/og_image.png',
  },
};
export const DEFAULT_IMAGE_LOADER = ({ src, width, quality }: any) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

export const EDITORS = [
  'ovidiu.smith@bets.io',
  'vwest@bets.io',
  'alex.bane@bets.io',
];

export const GENERAL_ERROR =
  'There was an error while processing your request. Please contact Customer Support.';

export const LOYALTY_REWARDS = {
  daily: {
    1: {
      reward: '10 FS',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '2 mɃTC',
        wager: '35x',
      },
    },
    2: {
      reward: '20 FS',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '2 mɃTC',
        wager: '35x',
      },
    },
    3: {
      reward: '30 FS',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '2 mɃTC',
        wager: '35x',
      },
    },
    4: {
      reward: '40 FS',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '2 mɃTC',
        wager: '35x',
      },
    },
    5: {
      reward: '50 FS',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '2 mɃTC',
        wager: '35x',
      },
    },
    6: {
      reward: '100 FS',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '2 mɃTC',
        wager: '35x',
      },
    },
    7: {
      reward: '200 FS',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '2 mɃTC',
        wager: '35x',
      },
    },
  },
  weekend: {
    1: {
      reward: '2%',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '100 mɃTC',
        wager: '3x',
      },
    },
    2: {
      reward: '4%',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '100 mɃTC',
        wager: '3x',
      },
    },
    3: {
      reward: '6%',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '100 mɃTC',
        wager: '3x',
      },
    },
    4: {
      reward: '7%',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '100 mɃTC',
        wager: '3x',
      },
    },
    5: {
      reward: '8%',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '100 mɃTC',
        wager: '3x',
      },
    },
    6: {
      reward: '9%',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '100 mɃTC',
        wager: '3x',
      },
    },
    7: {
      reward: '10%',
      conditions: {
        min_deposit: '0.5 mɃTC',
        max_win: '100 mɃTC',
        wager: '3x',
      },
    },
  },
  weekly: {
    1: {
      reward: '10%',
      conditions: {
        min_deposit: '0.6 mɃTC',
        max_win: '10 mɃTC',
        wager: '35x',
      },
    },
    2: {
      reward: '20%',
      conditions: {
        min_deposit: '0.6 mɃTC',
        max_win: '20 mɃTC',
        wager: '35x',
      },
    },
    3: {
      reward: '30%',
      conditions: {
        min_deposit: '0.6 mɃTC',
        max_win: '30 mɃTC',
        wager: '35x',
      },
    },
    4: {
      reward: '40%',
      conditions: {
        min_deposit: '0.6 mɃTC',
        max_win: '40 mɃTC',
        wager: '35x',
      },
    },
    5: {
      reward: '50%',
      conditions: {
        min_deposit: '0.6 mɃTC',
        max_win: '50 mɃTC',
        wager: '35x',
      },
    },
    6: {
      reward: '75%',
      conditions: {
        min_deposit: '0.6 mɃTC',
        max_win: '75 mɃTC',
        wager: '35x',
      },
    },
    7: {
      reward: '100%',
      conditions: {
        min_deposit: '0.6 mɃTC',
        max_win: '100 mɃTC',
        wager: '35x',
      },
    },
  },
};

export const LOYALTY_DEFAULT_LEVELS = [
  {
    name: 'Loyalty weekly reload group 0 ',
    id: 'loyalty_weekly_reload_group0',
  },
  {
    name: 'Weekend cashback group 0',
    id: 'weekend_cashback_group0',
  },
  {
    name: 'Loyalty daily spins group 0',
    id: 'loyalty_daily_spins_group0',
  },
];

export const SETTINGS_TABS = [
  {
    id: 'general',
    label: 'General',
    active: true,
  },
  {
    id: 'security',
    label: 'Security',
    active: false,
  },
  {
    id: 'sessions',
    label: 'Active Sessions',
    active: false,
  },
  {
    id: 'kyc',
    label: 'Verification',
    active: false,
  },
  {
    id: 'limits',
    label: 'Responsible Gambling',
    active: false,
  },
];

export const DEFAULT_BONUSES = [
  'first_deposit_bonus',
  'second_deposit_bonus',
  'third_deposit_bonus',
  'fourth_deposit_bonus',
];

export const WHITE_ICONS = ['ETH', 'BTC', 'LTC', 'USDT', 'BCH', 'DOG'];

export const DEFAULT_CONTENT_STALE_TIME = Infinity;

export const PRIVATE_WS_CHANNELS = [
  `analytics`,
  `bonuses_changes`,
  `payments_changes`,
  `freespins_changes`,
  `lootboxes_changes`,
  `groups_updates_separated_by_type`,
  `comps_award`,
];

export const SORTING_OPTIONS = [
  {
    key: 'global',
    value: 'A-Z',
  },
  {
    key: 'novelty',
    value: 'Novelty',
  },
  {
    key: 'popularity',
    value: 'Popularity',
  },
];

export const CURRENCY_NAMES: any = {
  BTC: 'Bitcoin',
  BCH: 'Bitcoin Cash',
  ETH: 'Ethereum',
  LTC: 'Litecoin',
  DOG: 'Dogecoin',
  USDT: 'Tether',
  XRP: 'Ripple',
  ADA: 'Cardano',
  TRX: 'TRON',
  BNB: 'Binance Coin',
  SOL: 'Solana',
  USDC: 'USD Coin',
};

export const POPULAR_CURRENCIES = ['BTC', 'USDT', 'LTC', 'TRX', 'ETH', 'DOG'];

export const CURRENCY_CONFIRMATIONS = {
  ADA: 15,
  BTC: 1,
  BCH: 6,
  LTC: 6,
  DOG: 6,
  ETH: 10,
  XRP: 3,
  USDT: 10,
  BNB: 1,
  TRX: 19,
  SOL: 1,
  USDC: 10,
};

export const LANGUAGES = [
  {
    key: 'en',
    name: 'English',
    flag: '🇬🇧',
  },
  {
    key: 'de',
    name: 'German',
    flag: '🇩🇪',
  },
];
export const RESTRICTED_COUNTRIES = [
  'NL',
  'AN',
  'AU',
  'EE',
  'SE',
  'ES',
  'GB',
  'FR',
  'CW',
];

export const INVALIDATE_GLOBAL_QUERIES = [
  'favoriteGames',
  'currentUser',
  'accounts',
  'heroCarousels',
  'promotions',
  'playerCompPoints',
  'playerSettings',
  'userAvatar',
  'playerLotteriesStatuses',
  'playerStats',
  'bonusesHistory',
  'playerBonuses',
  'playerFreeSpins',
  'playersChatInfo',
];

type TierItem = {
  id: number;
  title: string;
  level?: string | number;
};

export const TIER_ITEMS: TierItem[] = [
  {
    id: 1,
    title: 'bronze',
  },
  {
    id: 2,
    title: 'silver',
  },
  {
    id: 3,
    title: 'gold',
  },
  {
    id: 4,
    title: 'platinum',
  },
  {
    id: 5,
    title: 'diamond',
  },
  {
    id: 6,
    title: 'ruby',
  },
  {
    id: 7,
    title: 'sapphire',
  },
];

export const EXCLUDED_CURRENCIES = ['USD', 'EUR'];

export const CUPONS_NO_DEPOSIT = [
  'NYXPR',
  'UCYKK',
  'YAAJO',
  'AGIQC',
  'YVJBU',
  'DLTBV',
  'NHPMW',
  'KRFSL',
  'VZZBQ',
  'TAJSX',
  'TKPHG',
  'PIKKW',
  'UGEZF',
  'KRAFL',
  'LQHBP',
];

export const WELCOME_PROMO_CODES = [
  'WILD',
  'LIVE',
  'SPORTS',
  'VIP',
  'EXCLUSIVE',
];

export const STATIC_PAGES = [
  '/about',
  '/bonus-terms',
  '/terms',
  '/privacy',
  '/responsible-gambling',
];

export const CURRENCY_ORDER = [
  'BTC', // Bitcoin
  'ETH', // Ethereum
  'XRP', // Ripple (XRP)
  'SOL', // Solana
  'USDT', // Tether
  'USDC', // USD Coin
  'DOG', // Dogecoin
  'BNB', // Binance Coin
  'ADA', // Cardano
  'TRX', // Tron
  'LTC', // Litecoin
  'BCH', // Bitcoin Cash
];

export const USD_STABLECOINS = ['USDT', 'USDC'];

export const MIRROR_DOMAINS = [
  'wild1.test:3000',
  'wild2.test:3000',
  'wild3.test:3000',
  'wild1.io',
  'wild2.io',
];
