'use client';

import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type RecaptchaActions =
  | 'signup'
  | 'signin'
  | 'forgotPassword'
  | 'resetPassword'
  | undefined
  | null;

/** Returns a function to perform the recaptcha action or the recaptcha token that was generated when the component */
const useRecaptcha = (): {
  handleReCaptchaVerify: (
    action: RecaptchaActions,
  ) => Promise<string | undefined>;
} => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (action: any) => {
      if (!executeRecaptcha) {
        // Execute recaptcha not yet available;
        return;
      }

      const token = await executeRecaptcha(action);
      return token;
    },
    [executeRecaptcha],
  );
  return {
    handleReCaptchaVerify,
  };
};

export default useRecaptcha;
