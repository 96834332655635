import React from 'react';
import { lexer, parser, setOptions, parseInline } from 'marked';
import Image from 'next/image';
import { FilteredGame } from '@/types/games_filters';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { DEFAULT_IMAGE_LOADER } from './constants';

export const createQueryString = (
  name: string,
  value: string,
  searchParams: ReadonlyURLSearchParams,
) => {
  const params = new URLSearchParams(searchParams as any);
  params.set(name, value);

  return params.toString();
};

export const getIconByCurrency = (
  currency: string,
  { small = false }: { small?: boolean } = {},
) => {
  switch (currency) {
    case 'btc':
      return (
        <Image
          src="/assets/accounts/BTC.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="BTC Icon"
        />
      );
    case 'bnb':
      return (
        <Image
          src="/assets/accounts/BNB.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="BNB Icon"
        />
      );
    case 'usd':
      return (
        <Image
          src="/assets/accounts/USD.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="USD Icon"
        />
      );
    case 'ada':
      return (
        <Image
          src="/assets/accounts/ADA.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="ADA Icon"
        />
      );
    case 'trx':
      return (
        <Image
          src="/assets/accounts/TRX.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="TRX Icon"
        />
      );
    case 'eur':
      return (
        <Image
          src="/assets/accounts/EUR.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="EUR Icon"
        />
      );
    case 'eth':
      return (
        <Image
          src="/assets/accounts/ETH.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="ETH Icon"
        />
      );
    case 'ltc':
      return (
        <Image
          src="/assets/accounts/LTC.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="LTC Icon"
        />
      );
    case 'bch':
      return (
        <Image
          src="/assets/accounts/BCH.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="BCH Icon"
        />
      );
    case 'dog':
      return (
        <Image
          src="/assets/accounts/DOG.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="DOGE Icon"
        />
      );
    case 'usdt':
      return (
        <Image
          src="/assets/accounts/USDT.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="USDT Icon"
        />
      );
    case 'xrp':
      return (
        <Image
          src="/assets/accounts/XRP.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="XRP Icon"
        />
      );
    case 'sol':
      return (
        <Image
          src="/assets/accounts/SOL.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="SOL Icon"
        />
      );
    case 'usdc':
      return (
        <Image
          loader={DEFAULT_IMAGE_LOADER}
          src="/assets/accounts/USDC.svg"
          width={small ? 12 : 16}
          height={small ? 12 : 16}
          alt="SOL Icon"
        />
      );
    // When adding new currency, add new case here
    default:
      return null;
  }
};

export const getPathByCategory = (category: string) => {
  const categoryToIconMap: any = {
    slots: 'slot-games',
    'table-games': 'table-games-new',
    roulette: 'roulette',
    jackpot: 'jackpot-new',
    'jackpot-games': 'jackpot-new',
    'bonus-buy': 'bonus-buy',
    providers: 'providers',
    recommended: 'crowns',
    popular: 'popular-games',
    new: 'new-games',
    recent: 'recently-played',
    favorites: 'favorite-games',
    loyalty: 'star-chest',
    cashback: 'coin-wallet',
    contact: 'cherries',
    live: 'live',
    prize: 'prize-icon',
    help: 'help',
    settings: 'settings-icon',
    search: 'search',
    tournaments: 'tournaments',
    loyalty2: 'loyalty-yellow',
    promotions: 'promotions',
    skill: 'skill-games-simple',
    default: 'all-games-new',
    'easter-rush': 'easter-rush',
    blackjack: 'blackjack',
    chat: 'chat',
    support: 'support',
    lobby: 'icon_lobby',
    latest: 'latest',
    megaways: 'icon_megaways',
    collections: 'icon_collections',
    all: 'all_games',
    themes: 'icon_themes',
    'game-shows': 'icon_game-shows',
    'fresh-releases': 'icon_fresh-releses',
    // "belatra-s-moneymania": "icon-belatra",
    // add new categories here for reusable category slider
  };

  return categoryToIconMap[category] || categoryToIconMap.default;
};

export const getIconByCategory = (category: string, size = 'large') => {
  const sizes: any = {
    extraSmall: { width: 16, height: 16 },
    small: { width: 20, height: 20 },
    large: { width: 32, height: 32 },
  };

  const { width, height } = sizes[size] || sizes.large;

  return (
    <Image
      width={width}
      height={height}
      src={`/assets/category-icons/${getPathByCategory(category)}.svg`}
      alt={`img-${category}`}
      className="object-contain"
      loader={DEFAULT_IMAGE_LOADER}
    />
  );
};

export const getColorByCurrency = (currency: string) => {
  switch (currency) {
    case 'btc':
      return 'background: #F89A2A;';
    case 'eth':
      return 'background: #7748FA;';
    case 'ltc':
      return 'background: #3572D1;';
    case 'bch':
      return 'background: #0AC18E;';
    case 'dog':
      return 'background: #BA9F33;';
    case 'neo':
      return 'background: #00DE94;';
    case 'usdt':
      return 'background: #50AF95;';
    case 'usd':
      return 'background: #6cde07;';
    case 'eur':
      return 'background: #0f8ff8;';
    case 'ada':
      return 'background: #4FAF95;';
    case 'bnb':
      return 'background: #F3BA2F;';
    case 'xrp':
      return 'background: #21692E;';
    case 'trx':
      return 'background: #EF0027;';
    default:
      return 'background: #222222;';
  }
};

const weekDays: any = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

export const getDayOfTheWeek = (currentDay: number) => {
  if (currentDay >= 0 && currentDay <= 6) {
    return weekDays[currentDay];
  } else return `Invalid day of the week.`;
};

export const processMarkdown = (
  markdown = '',
  options = null as any,
  inline = false,
) => {
  if (markdown == null) return;
  if (options && options?.markedOptions) {
    setOptions(options.markedOptions);
  }

  const tokens = lexer(markdown);

  let html: any = parser(tokens);
  if (inline) {
    html = parseInline(markdown);
  }
  return html;
};

export const handleError = (errors: any, field: string): any => {
  const error = errors && errors[field];
  if (error) {
    return Object.values(error)[0] as any;
  }
  return undefined;
};

export const handleAllErrors = (errors: any): string[] => {
  const errorMessages: string[] = [];

  if (errors && errors.errors) {
    for (const field in errors.errors) {
      if (Array.isArray(errors.errors[field])) {
        errorMessages.push(...errors.errors[field]);
      }
    }
  }

  return errorMessages;
};

export const handleChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  type: string,
  state: any = {},
  setState: any = () => null,
) => {
  const target = e.target;
  setState({ ...state, [type]: target.value });
};

export const formatStringTitle = (str: string) => {
  const parts = str.split(' ');
  const firstPart = parts[0];
  const secondPart = parts[1];

  return (
    <>
      <span className="text-sm font-semibold text-base-secondary">
        {firstPart}
      </span>{' '}
      <span className="text-sm font-semibold text-text-default">
        {secondPart}
      </span>
    </>
  );
};

export const getRandomFromArray = (arr?: any[], n?: number) => {
  if (!arr || arr.length === 0 || !arr || !n) return [];
  const result = new Array(n);
  let len = arr.length;
  const taken = new Array(len);
  if (n > len)
    throw new RangeError('getRandom: more elements taken than available');
  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export const getGameCategory = (game: FilteredGame) => {
  // @ts-ignore
  return Object.keys(game?.collections).includes('table-games')
    ? 'Table Games'
    : // @ts-ignore
      Object.keys(game?.collections).includes('slots')
      ? 'Slots'
      : '';
};
export const getPageIconByCategory = (
  category: string,
  height?: number,
  width?: number,
) => {
  const getImageName = () => {
    switch (category) {
      case 'slots':
        return 'Slots2';
      case 'table-games':
        return 'billiards';
      case 'roulette':
        return 'roulette-page';
      case 'jackpot':
        return 'seven';
      case 'bonus-buy':
        return 'cocktail';
      case 'providers':
        return 'providers-page';
      case 'recommended':
        return 'providers';
      case 'popular':
        return 'horseshoe';
      case 'new':
        return 'new-games';
      case 'recent':
        return 'timer';
      case 'skill-games':
        return 'skill-games';
      case 'favorites':
        return 'favorite';
      case 'loyalty':
        return 'star-chest';
      case 'cashback':
        return 'coin-wallet';
      case 'contact':
        return 'cherries';
      case 'live-casino':
        return 'controller';
      case 'all-games':
        return 'controller';
      case 'promotions':
        return 'lightning';
      case 'blackjack':
        return 'blackjack';
      case 'fresh-releases':
        return 'new-games';
      case 'top-games':
        return 'top-games';
      case 'tournaments':
        return 'cup1';
      case 'settings':
        return 'settings';
      case 'megaways':
        return 'megaways';
      default:
        return 'all-games';
    }
  };

  const imageName = getImageName();

  return (
    <Image
      alt={`image ${category}`}
      height={height ? height : 32}
      width={width ? width : 32}
      src={`assets/page-icons/${imageName}.svg`}
    />
  );
};
export const capitalizedWord = (word: string) => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const generateReferralCode = (length = 6) => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n)).toUpperCase();
  }
  return retVal;
};

export const detectUserAgent = (userAgent: string) => {
  return /android|ipad|iphone|ipod|blackberry|windows phone|opera mini|tablet/i.test(
    userAgent,
  );
};
